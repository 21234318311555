<template>
  <router-view/>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'staticJsonThemeGetter',
      'staticSiteFonts'
    ])
  },
  mounted() {
    this.$nextTick(function () {
      setTimeout( () => {
        let customerSource = localStorage.getItem('customerSource');
        axios.get(window.config.apiUrl + `/static/site${ customerSource ? `?source=${customerSource}` : '' }`)
        .then(response => {
            this.setStaticAction(response.data).then(() => {
              this.$vuetify.theme.themes.light.colors = {...this.$vuetify.theme.themes.light.colors, ...this.staticJsonThemeGetter}
            }).then(() => {
              let head = document.querySelector('head');
              let link = document.createElement('link');
              link.setAttribute('href', response.data.siteFonts.font);
              link.setAttribute('rel', 'stylesheet');
              head.append(link);
              let style = document.createElement('style');
              style.innerHTML = response.data.siteFonts.fontStyle;
              head.append(style)
            });
            document.querySelector('body').classList.remove('loading');
            document.querySelector('.loader').classList.add('hidden');
        
          // Vuetify.framework.theme.themes.light.primary = response.data.siteThemeColor;
          // document.documentElement.style.setProperty("--primary-color", response.data.siteThemeColor);
        })
        }, 500)
     
    })
  },
  methods: {
      ...mapActions([
      'setStaticAction',
    ]),
  } 
}
</script>

<style>

.custom-content a {
  font-weight: 600;
  color: rgb(var(--v-theme-primary));
  text-decoration: none;
}

.v-container{
  max-width: 1400px !important;
}


.v-navigation-drawer {
  will-change: initial !important
}

  .v-label{
     white-space: normal !important;
  }

  /* .splide__pagination {
    bottom: -1.5em !important;
  } */

    .user-description ol, .user-description ul{
    margin-left: 20px !important
  }

  .user-description > h6{
    font-size: .9rem !important;
    margin: 10px 0;
  }

</style>
