import 'vuetify/styles'
import { aliases, fa } from 'vuetify/lib/iconsets/fa'
import { ru } from 'vuetify/locale'
import { createVuetify } from 'vuetify'

export default createVuetify({
  locale: {
    defaultLocale: 'ru',
    messages: { ru }
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    }
  },
  theme: { 
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#0093c1',
          header_color: '#ffffff',
          footer_color: '#233a1c',
          main_color:'#ffffff',
          text:'#000000',
          text_title:'#FDF5EF',
          user_slider: '#233a1c',
          news_bg: '#ffffff',
          footer_bg: '#eeeeee',
          text_accent: '#0093c1',
          expand: '#ffffff',
        }
      },
      dark: {
        colors: {
          primary: '#0093c1',
          header_color: '#ffffff',
          footer_color: '#233a1c',
          main_color:'#FDF5EF',
          text:'#FDF5EF',
          text_title:'#FDF5EF',
        }
      },
    },
  }
})
