import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import router from './router'
import store from './store/store'
// import { createMetaManager } from 'vue-meta'
import Maska from 'maska';
import moment from 'moment'
import 'moment/locale/ru'



loadFonts()

let app = createApp(App);

const urlParams = new URL(window.location.href);
if (urlParams.searchParams.has('source') && localStorage.getItem('customerSource') === null) {
  localStorage.setItem('customerSource', urlParams.searchParams.get('source'));
} else {
  if (document.referrer) {
    const refUrl = new URL(document.referrer);
    localStorage.setItem('customerSource', refUrl.host);
  }
}

moment.locale('ru');
app.config.globalProperties.$moment = moment




app.use(router)
  .use(vuetify)
  .use(VueSplide)
  .use(store)
  .use(moment)
  .use(Maska)
  // .use(createMetaManager()) // add this line
  .mount('#app');


  app.config.globalProperties.$display = vuetify.display;
