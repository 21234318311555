import { createStore } from 'vuex'
import axios from "axios"

// Create a new store instance.
export default createStore({
	state () {
		return {
			static: {
				users: [],
				jsonSiteServiceButton: '',
				jsonSiteThemeColor: {},
				siteFonts: {}
			},
			createAppointmentNew: {
				appointmentToken: null,
				time: '',
				date: '',
				address: '',
				age: 3,
				width: 0,
				userId: null,
				idSpecial: '',
				service: null,
				users: {},
				q: '',
		},
	}
	},
	mutations: {
		setStatic(state, data) {
			state.static = data
		},
		setAppointmentToken(state, data){
			state.createAppointmentNew.appointmentToken = data
		},
		setAddress(state, data){
			state.createAppointmentNew.address = data
		},
		setTime(state, data){
			state.createAppointmentNew.time = data
		},
		setDate(state, date){
			state.createAppointmentNew.date = date
		},
		setAdult(state, data){
			state.createAppointmentNew.adult = data
		},
		setWidth(state, data){
			state.createAppointmentNew.width = data
		},
		setUsers(state, data){
			state.createAppointmentNew.users = data
		},
		setUserId(state, data){
			state.createAppointmentNew.userId = data
		},
		setSpecialId(state, data){
			console.log(data);
			state.createAppointmentNew.idSpecial = data
		},
		setQ(state, data){
			state.createAppointmentNew.q = data
		},
		setService(state, data){
			state.createAppointmentNew.service = data
		},
		setAge(state, age){
			state.createAppointmentNew.age = age
		},
		setFilial(state, data) {
			state.createAppointmentNew.filial = data;
		}
	},
	actions: {
		setStaticAction({commit}, data){
			commit('setStatic', data);
		},
		getUsers({commit, state}, {idSpecial, idService, idUser, age}){
			commit('setUsers', {});
			return axios({
				url: window.config.apiUrl + 
				'/widgets/get-users' + 
				'?dateFrom=' + state.createAppointmentNew.date + 
				'&dateTo=' + state.createAppointmentNew.date + 
				'&showFreeIntervals=true' +
				'&showUserSchedule=true' +
				'&idSpecial=' + idSpecial +
				'&idUser=' + (idUser ? idUser : '') +
				'&idFilial=' + (state.createAppointmentNew.filial ? state.createAppointmentNew.filial : '') +
				'&customerAge=' + (age ? age : '') +
				(idService ? '&idService=' + idService : ''),
				method: 'GET',
			})
			.then(res => {
				commit('setUsers', res.data.users)
			})
			// .catch(err => {console.log(err)})
		},
		getUser({state}, {dateTo, showFreeIntervals, showUserServices}){
			return axios({
				url: window.config.apiUrl + 
				'/widgets/get-users?idUser=' + state.createAppointmentNew.userId + 
				'&dateTo=' + dateTo + 
				'&showUserSchedule=true' +
				'&showFreeIntervals=' + showFreeIntervals +
				'&showUserServices=' + showUserServices,
				method: 'GET',
			})
			.then(res => res.data.users[state.createAppointmentNew.userId])
			.catch(err => {console.log(err)})
		},
		getUsersSpecials(ctx, q){
			return axios({
				url: window.config.apiUrl + '/widgets/search-users?searchQuery=' + q,
				method: 'GET',
			})
		},
		getServices(ctx, q){
			return axios({
				url: window.config.apiUrl + '/widgets/search-services?userServices=true&searchQuery=' + q,
				method: 'GET',
			})
		},
		getServiceById({commit}, id){
			return axios({
				url: window.config.apiUrl + '/widgets/search-services?idService=' + id,
				method: 'GET',
			}).then(res => {
				if (res.data.services.length) {
					commit('setService', res.data.services[0])
				}
			})
		}
	},
	getters: {
		staticSiteFonts(state) {
			return state.static.siteFonts
		},
		staticJsonThemeGetter(state) {
			return state.static.jsonSiteThemeColor
		},
		defaultTitle(state) {
			return state.static.nameClient
		},
		staticGetter(state) {
			return state.static
		},
		age(state){
			return state.createAppointmentNew.age
		},
		newsGetter(state) {
			return state.static.news
		},
		slidesGetter(state){
			return state.static.slides
		},
		promotionsGetter(state) {
			return state.static.promotions
		},
		usersGetter(state) {
			return state.static.users
		},
		
		randomUsersGetter (state) { 
			return state.static.users ? Object.values(state.static.users).sort(function() {
				return Math.random() - 0.5
			}) : []
		},
		
		reviewsGetter(state) {
			return state.static.reviews
		},
		documentsGetter(state){
			return state.static.documents
		},
		servicesButtons(state){
			if (state.static.jsonSiteServiceButton){
				return JSON.parse(state.static.jsonSiteServiceButton.replace("\\","\\\\"));
			} else {
				return [];
			}
		},
		usersButtons(state){
			if (state.static.jsonSiteUserButton){
				return JSON.parse(state.static.jsonSiteUserButton.replace("\\","\\\\"));
			} else {
				return [];
			}
		},
		appointmentToken(state){
			return state.createAppointmentNew.appointmentToken
		},
		date(state){
			return state.createAppointmentNew.date
		},
		time(state){
			return state.createAppointmentNew.time
		},
		address(state){
			return state.createAppointmentNew.address
		},
		adult(state){
			return state.createAppointmentNew.adult
		},
		currentProgress(state){
			return state.createAppointmentNew.currentProgress
		},
		width(state){
			return state.createAppointmentNew.width
		},
		currentDate(state){
			return state.createAppointmentNew.date
		},
		users(state){
			return Object.values(state.createAppointmentNew.users).sort((a,b) => {
				if (a.surname < b.surname) return -1
			})
		},
		temp(state){
			return state.createAppointmentNew.users;
		},
		activeUsers(state){
			const activeUsers = {}
			for (let key in state.createAppointmentNew.users) {
        if (state.createAppointmentNew.users[key].freeIntervals) activeUsers[key] = state.createAppointmentNew.users[key]
			}
			return Object.values(activeUsers).sort((a,b) => {
				if (a.surname < b.surname) return -1
			})
		},
		userId(state){
			return state.createAppointmentNew.userId
		},
		idSpecial(state){
			return state.createAppointmentNew.idSpecial
		},
		min(state){
			return state.createAppointmentNew.width < 700
		},
		service(state){
			return state.createAppointmentNew.service
		},
		q(state){
			return state.createAppointmentNew.q
		},
		serviceActive(state){
      return state.createAppointmentNew.service ? 
      {
        nameService: state.createAppointmentNew.service.name,
        priceService: state.createAppointmentNew.service.price,
        id: state.createAppointmentNew.service.id,
        requirePrepaymentInApp: state.createAppointmentNew.service.requirePrepaymentInApp,
      } :
      null
		}
}
})
